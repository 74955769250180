import { template as template_05de86b0452b4d3c8a939ee69138f3fd } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_05de86b0452b4d3c8a939ee69138f3fd(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_05de86b0452b4d3c8a939ee69138f3fd(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_05de86b0452b4d3c8a939ee69138f3fd(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
