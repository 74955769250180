import { template as template_a951013e7ca84ad6ab0397dfb16a1084 } from "@ember/template-compiler";
const WelcomeHeader = template_a951013e7ca84ad6ab0397dfb16a1084(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
