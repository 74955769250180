import { template as template_45e0026e681343d2a3692f192484b243 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_45e0026e681343d2a3692f192484b243(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
