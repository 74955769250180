import { template as template_70f2b5867565442cb51d208413938657 } from "@ember/template-compiler";
const FKControlMenuContainer = template_70f2b5867565442cb51d208413938657(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
